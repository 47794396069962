<template>
  <v-container>
    <div
      class="text-h6 text-sm-h5 font-weight-bold text-center text-sm-left mb-6"
    >
      Solicitações de Saques
    </div>

    <loader v-if="loading" />

    <section v-else>
      <v-data-table
        class="rounded-lg elevation-1 mb-4"
        :headers="headers"
        :items="withdrawals"
        disable-pagination
        disable-sort
        hide-default-footer
      >
        <template v-slot:[`item.user`]="{ item }">
          <v-chip :to="`/users/${item.user.id}`" class="px-2" color="neutral1">
            <v-avatar class="mr-2" color="primary" size="1.5rem">
              <v-img v-if="item.user.image" :src="item.user.image" />
              <v-icon v-else dark small>mdi-account-outline</v-icon>
            </v-avatar>

            <span>{{ item.user.name || "--" }}</span>
          </v-chip>
        </template>

        <template v-slot:[`item.document`]="{ item }">
          {{ formatDocument(item.user.document) }}
        </template>

        <template v-slot:[`item.createdAt`]="{ item }">
          {{ formatDate(item.createdAt) }}
        </template>

        <template v-slot:[`item.processAt`]="{ item }">
          {{ formatDate(item.processAt) || "--" }}
        </template>

        <template v-slot:[`item.value`]="{ item }">
          R$ {{ currencyMask(item.value) }}
        </template>

        <template v-slot:[`item.finalValue`]="{ item }">
          <div class="d-flex flex-column">
            <div>R$ {{ currencyMask(item.finalValue) }}</div>

            <div class="text-caption grey--text">Taxa {{ item.rate }}%</div>
          </div>
        </template>

        <template v-slot:[`item.status`]="{ item }">
          <v-chip :color="`${handleStatus(item.status).color} lighten-4`">
            <span
              :class="`${handleStatus(item.status).color}--text text--darken-2`"
            >
              {{ handleStatus(item.status).label }}
            </span>
          </v-chip>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-btn small icon @click="manageWithdraw(item)">
            <v-icon>mdi-open-in-app</v-icon>
          </v-btn>
        </template>
      </v-data-table>

      <v-pagination
        v-model="pagination.page"
        :length="pagination.pageCount"
        :total-visible="5"
      />
    </section>

    <ManageWithdraw ref="manageWithdraw" @success="getWithdrawals(false)" />
  </v-container>
</template>

<script>
import { request } from "@/services";
import {
  currencyMask,
  formatDocument,
  formatDate,
  handlePagination,
} from "@/utils";
import ManageWithdraw from "./partials/manageWithdraw/ManageWithdraw.vue";

export default {
  components: {
    ManageWithdraw,
  },

  data() {
    return {
      loading: true,
      headers: [
        { text: "ID", value: "id" },
        { text: "Usuário", value: "user" },
        { text: "CPF/CNPJ", value: "document" },
        { text: "Data de solicitação", value: "createdAt" },
        { text: "Data de processamento", value: "processAt" },
        { text: "Valor solicitado", value: "value" },
        { text: "Valor final", value: "finalValue" },
        { text: "Status", value: "status" },
        { text: "Ações", value: "actions" },
      ],
      withdrawals: [],
      pagination: {
        page: 1,
        pageCount: 1,
      },
    };
  },

  beforeMount() {
    this.getWithdrawals();
  },

  watch: {
    ["pagination.page"]() {
      this.getWithdrawals();
    },
  },

  methods: {
    async getWithdrawals(reload = true) {
      try {
        if (reload) this.loading = true;

        const payload = {
          method: "listSaques",
          page: this.pagination.page,
        };

        const { data } = await request(payload);

        this.withdrawals = data.saques.map((el) => ({
          id: el.saque_id,
          user: {
            id: el.user_id,
            email: el.email,
            name: el.nome,
            image: el.profileImage
              ? this.$store.getters.apiURL + "/" + el.profileImage
              : null,
            document: el.cpfCnpj,
          },
          createdAt: el.dataSolicitacao,
          processAt: el.dataProcessamento,
          rate: Number(el.taxaSaque),
          value: Number(el.valor),
          finalValue:
            Number(el.valor) - (Number(el.taxaSaque) / 100) * Number(el.valor),
          status: el.status,
        }));

        this.pagination.pageCount = handlePagination(data.total);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    manageWithdraw(data) {
      this.$refs.manageWithdraw.open(data);
    },

    handleStatus(value) {
      switch (String(value)) {
        case "-1":
          return {
            label: "Recusado",
            color: "red",
          };
        case "0":
          return {
            label: "Aguardando",
            color: "amber",
          };
        case "1":
          return {
            label: "Aprovado",
            color: "green",
          };
        default: {
          return {
            label: "Processando",
            color: "grey",
          };
        }
      }
    },

    currencyMask,

    formatDocument,

    formatDate,
  },
};
</script>

<style></style>
