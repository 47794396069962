<template>
  <v-container>
    <div
      class="text-h6 text-sm-h5 font-weight-bold text-center text-sm-left mb-6"
    >
      Listagem de vendas
    </div>

    <loader v-if="loading" />

    <section v-else>
      <v-data-table
        class="elevation-1 mb-4"
        :headers="headers"
        :items="sales"
        disable-pagination
        disable-sort
        hide-default-footer
      >
        <template v-slot:[`item.user`]="{ item }">
          <v-chip :to="`/users/${item.user.id}`" class="px-2" color="neutral1">
            <v-avatar class="mr-2" color="primary" size="1.5rem">
              <v-img v-if="item.user.image" :src="item.user.image" />
              <v-icon v-else dark small>mdi-account-outline</v-icon>
            </v-avatar>

            <span>{{ item.user.name || "--" }}</span>
          </v-chip>
        </template>

        <template v-slot:[`item.document`]="{ item }">
          {{ formatDocument(item.user.document) }}
        </template>

        <template v-slot:[`item.createdAt`]="{ item }">
          {{ formatDate(item.createdAt) }}
        </template>

        <template v-slot:[`item.paidAt`]="{ item }">
          {{ formatDate(item.paidAt) || "--" }}
        </template>

        <template v-slot:[`item.sortedAt`]="{ item }">
          {{ formatDate(item.sortedAt) || "--" }}
        </template>

        <template v-slot:[`item.earnedValue`]="{ item }">
          <span
            :class="`${item.awarded ? 'green' : 'red'}--text font-weight-bold`"
          >
            R$ {{ currencyMask(item.earnedValue) }}
          </span>
        </template>

        <template v-slot:[`item.status`]="{ item }">
          <v-chip
            :color="`${
              handleStatus(item.status, item.awarded).color
            } lighten-4`"
          >
            <span
              :class="`${
                handleStatus(item.status, item.awarded).color
              }--text text--darken-2`"
            >
              {{ handleStatus(item.status, item.awarded).label }}
            </span>
          </v-chip>
        </template>
      </v-data-table>

      <v-pagination
        v-model="pagination.page"
        :length="pagination.pageCount"
        :total-visible="5"
      />
    </section>
  </v-container>
</template>

<script>
import { request } from "@/services";
import {
  currencyMask,
  formatDocument,
  formatDate,
  handlePagination,
} from "@/utils";

export default {
  data() {
    return {
      loading: true,
      headers: [
        { text: "ID", value: "id" },
        { text: "Usuário", value: "user" },
        { text: "CPF/CNPJ", value: "document" },
        { text: "Data de solicitação", value: "createdAt" },
        { text: "Data do pagamento", value: "paidAt" },
        { text: "Data do sorteio", value: "sortedAt" },
        { text: "Valor ganho", value: "earnedValue" },
        { text: "Status", value: "status" },
      ],
      sales: [],
      pagination: {
        page: 1,
        pageCount: 1,
      },
    };
  },

  beforeMount() {
    this.getSales();
  },

  watch: {
    ["pagination.page"]() {
      this.getSales();
    },
  },

  methods: {
    async getSales() {
      try {
        this.loading = true;

        const payload = {
          method: "listUserRaspadinhas",
          page: this.pagination.page,
        };

        const { data } = await request(payload);

        this.sales = data.usersRaspadinha.map((el) => ({
          id: el.userRaspadinha_id,
          createdAt: el.createdAt,
          paidAt: el.paiedAt,
          sortedAt: el.sorteadaAt,
          user: {
            id: el.user_id,
            document: el.cpfCnpj,
            name: el.nome,
            email: el.email,
            image: el.profileImage
              ? this.$store.getters.apiURL + "/" + el.profileImage
              : null,
          },
          hash: el.hash,
          awarded: el.premiada === "1",
          fields: el.raspadinhaFields ? JSON.parse(el.raspadinhaFields) : null,
          status: el.status,
          earnedValue: Number(el.valorGanho),
        }));

        this.pagination.pageCount = handlePagination(data.total);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    handleStatus(status, winner) {
      if (winner) {
        return {
          label: "Premiada",
          color: "orange",
        };
      }

      if (status === "-1")
        return {
          label: "Pag. negado",
          color: "red",
        };

      if (status === "0")
        return {
          label: "Pag. não efetuado",
          color: "grey",
        };

      if (status === "1")
        return {
          label: "Aguardando",
          color: "amber",
        };

      if (status === "2")
        return {
          label: "Pag. efetuado",
          color: "green",
        };

      if (status === "3")
        return {
          label: "Sorteada",
          color: "blue",
        };
    },

    currencyMask,

    formatDocument,

    formatDate,
  },
};
</script>

<style></style>
