import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import pt from "vuetify/lib/locale/pt";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#00CCA6",
        secondary: "#083A50",
        success: "#2A8C62",
        error: "#FC2E01",
        warning: "#FFAB00",
        info: "#00B8D9",
        neutral: "#FFFFFF",
        neutral1: "#F5F5F5",
        neutral2: "#BCBDBD",
        neutral3: "#8B8B8D",
        neutral4: "#58595B",
        neutral5: "#464749",
        neutral6: "#303132",
        neutral7: "#000000",
      },
    },
  },
  lang: {
    locales: { pt },
    current: "pt",
  },
});
