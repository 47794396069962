import Vue from "vue";
import VueRouter from "vue-router";
import beforeEach from "./beforeEach";
import Auth from "../views/auth/Auth.vue";
import Home from "../views/home/Home.vue";
import Users from "../views/users/Users.vue";
import Scratchs from "../views/scratchs/Scratchs.vue";
import Deposits from "../views/deposits/Deposits.vue";
import Withdrawals from "../views/withdrawals/Withdrawals.vue";
import Sales from "../views/sales/Sales.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/auth",
    name: "Auth",
    meta: { preventAuth: true },
    component: Auth,
  },
  {
    path: "/home",
    name: "Home",
    meta: { requiresAuth: true },
    component: Home,
  },
  {
    path: "/users",
    component: Users,
    children: [
      {
        path: "",
        name: "Users",
        meta: { requiresAuth: true },
        component: () => import("../views/users/children/users/Users.vue"),
      },
      {
        path: ":id",
        name: "UserProfile",
        meta: { requiresAuth: true },
        component: () =>
          import("../views/users/children/userProfile/UserProfile.vue"),
      },
    ],
  },
  {
    path: "/scratchs",
    name: "Scratchs",
    meta: { requiresAuth: true },
    component: Scratchs,
  },
  {
    path: "/deposits",
    name: "Deposits",
    meta: { requiresAuth: true },
    component: Deposits,
  },
  {
    path: "/withdrawals",
    name: "Withdrawals",
    meta: { requiresAuth: true },
    component: Withdrawals,
  },
  {
    path: "/sales",
    name: "Sales",
    meta: { requiresAuth: true },
    component: Sales,
  },
  {
    path: "*",
    redirect: "/home",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(beforeEach);

export default router;
