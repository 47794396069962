<template>
  <v-dialog v-model="dialog" width="600">
    <v-card v-if="!!withdraw">
      <div class="pa-6">
        <div class="d-flex mb-4">
          <span class="text-h6">Detalhes do saque</span>

          <v-spacer />

          <v-btn icon @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>

        <div class="mb-4">
          <v-list class="transparent pa-0" nav>
            <v-list-item
              v-for="(item, i) in _withdraw"
              v-show="item.value"
              class="neutral1 rounded-lg mb-2"
              :key="i"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.value }}
                </v-list-item-title>

                <v-list-item-subtitle>
                  {{ item.label }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <!--status -->
            <v-list-item class="neutral1 rounded-lg mb-2">
              <v-list-item-content>
                <v-list-item-title
                  :class="`${
                    handleStatus(withdraw.status).color
                  }--text font-weight-bold`"
                >
                  {{ handleStatus(withdraw.status).label }}
                </v-list-item-title>
                <v-list-item-title> Status da transação </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>

        <div class="d-flex justify-end">
          <v-btn class="text-none mr-2" plain @click="close()"> Fechar </v-btn>

          <v-btn
            v-if="withdraw.status === '0'"
            color="red"
            class="text-none mr-2"
            dark
            depressed
            @click="submit(-1)"
          >
            Rejeitar
          </v-btn>

          <v-btn
            v-if="withdraw.status === '0'"
            color="green"
            class="text-none"
            dark
            depressed
            @click="submit(1)"
          >
            Aprovar
          </v-btn>
        </div>
      </div>

      <loader-hover v-if="loading" />
    </v-card>
  </v-dialog>
</template>

<script>
import { request } from "@/services";
import { formatDocument, currencyMask } from "@/utils";
import { mask } from "vue-the-mask";

export default {
  data() {
    return {
      dialog: false,
      loading: false,
      withdraw: null,
    };
  },

  directives: {
    mask,
  },

  computed: {
    _withdraw() {
      return [
        {
          value: this.withdraw.user.name || null,
          label: "Nome do cliente",
        },
        {
          value: this.withdraw.user.email || null,
          label: "E-mail",
        },
        {
          value: formatDocument(this.withdraw.user.document),
          label: "CPF/CNPJ",
        },
        {
          value: "R$ " + currencyMask(this.withdraw.value),
          label: "Valor solicitado",
        },
        {
          value: "R$ " + currencyMask(this.withdraw.finalValue),
          label: "Valor final",
        },
      ];
    },
  },

  methods: {
    async submit(status) {
      try {
        this.loading = true;

        const payload = {
          method: "setSaqueStatus",
          status: status,
          saqueID: this.withdraw.id,
        };

        await request(payload);

        this.handleAlert("Ação realizada com sucesso", "success");
        this.$emit("success");
        this.close();
      } catch (error) {
        this.handleAlert(error.data.message, "error");
      } finally {
        this.loading = false;
      }
    },

    handleStatus(value) {
      switch (String(value)) {
        case "-1":
          return {
            label: "Recusado",
            color: "red",
          };
        case "0":
          return {
            label: "Aguardando",
            color: "amber",
          };
        case "1":
          return {
            label: "Aprovado",
            color: "green",
          };
        default: {
          return {
            label: "Processando",
            color: "grey",
          };
        }
      }
    },

    open(data) {
      this.dialog = true;
      this.withdraw = data;
    },

    close() {
      this.dialog = false;
      this.withdraw = null;
    },

    handleAlert(title, type) {
      this.$root.$emit("alert", { title, type });
    },

    currencyMask,
  },
};
</script>

<style>
.field:focus {
  outline: none !important;
  border: 0px;
}
</style>
