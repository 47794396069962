<template>
  <v-dialog v-model="dialog" max-width="600" persistent>
    <v-card v-if="dialog" class="rounded-lg pa-6" color="neutral">
      <div class="d-flex align-center mb-4">
        <span class="text-h6">
          {{ _editMode ? "Editar raspadinha" : "Criar raspadinha" }}
        </span>

        <v-spacer />

        <v-btn icon @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <v-form v-model="valid" ref="form">
        <div class="text-body-2">Nome da raspadinha</div>
        <v-text-field
          v-model="form.name"
          background-color="neutral1"
          solo
          flat
          :rules="_rules"
        />

        <div class="text-body-2">Preço de venda</div>
        <v-text-field
          v-model="form.price"
          v-money="money"
          class="mb-2"
          background-color="neutral1"
          prefix="R$ "
          solo
          flat
          :rules="_valueRules"
        />

        <div class="text-body-2">Valor do premio</div>
        <v-text-field
          v-model="form.prizeAmount"
          v-money="money"
          id="prizeAmount"
          background-color="neutral1"
          prefix="R$ "
          solo
          flat
          :rules="_valueRules"
        />

        <div class="text-body-2">Probabilidade de ganhar (1 à 100%)</div>
        <v-text-field
          v-model="form.probability"
          background-color="neutral1"
          prefix="% "
          solo
          flat
          :rules="_percentRules"
        />

        <div class="text-body-2">Valor retido (1 à 100%)</div>
        <v-text-field
          v-model="form.lockedAmount"
          background-color="neutral1"
          prefix="% "
          solo
          flat
          :rules="_percentRules"
        />

        <v-switch
          v-model="form.available"
          class="ma-0"
          :label="form.available ? 'Disponível' : 'Indisponível'"
        />
      </v-form>

      <div class="d-flex">
        <v-spacer />

        <v-btn class="text-none" plain @click="close()">Cancelar</v-btn>

        <v-btn class="text-none" color="primary" depressed @click="submit()">
          Confirmar
        </v-btn>
      </div>

      <loader-hover v-if="loading" />
    </v-card>
  </v-dialog>
</template>

<script>
import { request } from "@/services";
import { currencyMask, parseNumber } from "@/utils";
import { VMoney } from "v-money";

export default {
  name: "ScratchDialog",

  data() {
    return {
      dialog: false,
      loading: false,
      valid: false,
      money: {
        decimal: ",",
        thousands: ".",
        precision: 2,
      },
      scratchID: "",
      form: {
        name: "",
        price: "0,00",
        prizeAmount: "0,00",
        probability: 1,
        lockedAmount: 1,
        available: true,
      },
    };
  },

  directives: {
    money: VMoney,
  },

  computed: {
    _editMode() {
      return !!this.scratchID;
    },

    _rules() {
      return [(v) => !!v || "Campo Obrigatório"];
    },

    _percentRules() {
      return [
        (v) => !!v || "Campo obrigatório",
        (v) => /^(\d+|\d+\.\d+)$/.test(v) || "Valor inválido",
      ];
    },

    _valueRules() {
      return [
        (v) => !!v || "Campo obrigatório",
        (v) => parseNumber(v) > 0 || "Valor inválido",
      ];
    },
  },

  methods: {
    async submit() {
      if (!this.$refs.form.validate()) return;

      try {
        this.loading = true;

        const payload = {
          method: "uploadRaspadinha",
          nome: this.form.name,
          valor: parseNumber(this.form.price),
          valorPremio: parseNumber(this.form.prizeAmount),
          probGanhar: this.form.probability,
          valorRetido: this.form.lockedAmount,
          disponivel: this.form.available ? "1" : "0",
          ...(this._editMode && { raspadinhaID: this.scratchID }),
        };

        await request(payload);

        this.$emit("success");
        this.handleAlert(
          this._editMode
            ? "Alterações salvas com sucesso"
            : "Raspadinha criada com sucesso",
          "success"
        );
        this.close();
      } catch (err) {
        this.handleAlert(err.data.message, "error");
      } finally {
        this.loading = false;
      }
    },

    open(data = null) {
      this.scratchID = data ? data.id : "";

      if (data) {
        this.form = {
          name: data.name,
          price: currencyMask(data.price),
          prizeAmount: currencyMask(data.prizeAmount),
          probability: data.probability,
          lockedAmount: data.lockedAmount,
          available: data.available,
        };
      }

      this.dialog = true;

      this.$nextTick(() => {
        this.$refs.form.resetValidation();
      });
    },

    close() {
      this.$refs.form.reset();
      this.dialog = false;
    },

    handleAlert(title, type) {
      this.$root.$emit("alert", { title, type });
    },
  },
};
</script>

<style></style>
