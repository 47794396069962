const moment = require("moment");
moment.locale("pt-BR");

export function formatAmount(value, decimal = 2) {
  if (!value) value = 0;

  const og = Math.pow(10, decimal);
  value = Math.floor(value * og) / og;

  return Number(value.toFixed(decimal));
}

export function currencyMask(value, min = 2, max = 2) {
  value = formatAmount(value);

  return value.toLocaleString("pt-BR", {
    minimumFractionDigits: min,
    maximumFractionDigits: max,
  });
}

export function parseNumber(value) {
  if (!value) value = "0,00";
  return Number(value.replace(/\./g, "").replace(/,/g, "."));
}

export function capitalizeAll(value) {
  const values = value.split(" ");

  return values
    .map((e) => capitalize(e))
    .toString()
    .replaceAll(",", " ");
}

export function capitalize(value) {
  return (
    value.toString().charAt(0).toUpperCase() +
    value.toString().substring(1).toLowerCase()
  );
}

export function formatDocument(value) {
  if (value.length === 11)
    return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");

  if (value.length === 14)
    return value.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      "$1.$2.$3/$4-$5"
    );

  return value;
}

export function onlyNumber(event) {
  event = event ? event : window.event;
  let charCode = event.which ? event.which : event.keyCode;

  if (charCode < 48 || charCode > 57) return event.preventDefault();
  else true;
}

export function blockNumber(event) {
  event = event ? event : window.event;
  let charCode = event.which ? event.which : event.keyCode;

  if (charCode < 48 || charCode > 57) return true;
  else event.preventDefault();
}

export function formatDate(value, format = "DD/MM/YYYY HH:mm:ss") {
  if (!value || value === "0000-00-00 00:00:00") return;

  return moment(value).format(format);
}

export function revertDate(value) {
  return moment(value, "DD/MM/YYYY").format("YYYY-MM-DD");
}

export function handlePagination(value, itemsPerPage = 15) {
  value = value / itemsPerPage;
  if (value % 1 !== 0) value = value + 1;

  return Math.trunc(value);
}
