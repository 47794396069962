<template>
  <v-app v-if="loaded" id="app">
    <v-app-bar
      v-if="_authenticated"
      class="pl-2 pr-4 elevation-1"
      color="white"
      app
    >
      <v-btn class="mr-2" small icon @click="drawer = !drawer">
        <v-icon>mdi-menu</v-icon>
      </v-btn>

      <v-spacer />

      <v-menu content-class="mt-2" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-avatar v-bind="attrs" v-on="on" size="2rem" color="neutral1">
            <v-icon>mdi-account-outline</v-icon>
          </v-avatar>
        </template>

        <v-list dense nav>
          <v-list-item @click="signOut()">
            <v-list-item-icon class="mr-2">
              <v-icon small>mdi-exit-to-app </v-icon>
            </v-list-item-icon>

            <v-list-item-title> Sair </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer
      v-if="_authenticated"
      v-model="drawer"
      color="white"
      app
    >
      <div class="d-flex align-center justify-center mt-5 mb-2">
        <v-img
          :src="require('@/assets/logo-black.svg')"
          max-width="9rem"
          contain
        />
      </div>

      <v-list class="transparent pa-4" dense>
        <router-link
          v-for="(item, i) in routes"
          v-slot="{ href, navigate, isActive }"
          :to="item.path"
          :key="i"
          custom
        >
          <v-list-item
            class="rounded-lg mb-1"
            :href="href"
            :class="`${!isActive || 'neutral1'}`"
            @click="navigate"
          >
            <v-list-item-icon class="mr-4">
              <v-icon :color="isActive ? 'primary' : 'grey darken-3'">
                {{ item.icon }}
              </v-icon>
            </v-list-item-icon>

            <v-list-item-title class="text-body-2">
              <span
                :class="
                  item.disabled
                    ? 'grey--text'
                    : isActive
                    ? 'primary--text'
                    : 'grey--text text--darken-3'
                "
              >
                {{ item.label }}
              </span>

              <v-spacer />
            </v-list-item-title>
          </v-list-item>
        </router-link>
      </v-list>
    </v-navigation-drawer>

    <v-main class="neutral1">
      <router-view class="py-10" />
    </v-main>

    <AlertBar ref="alertBar" />
  </v-app>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { request } from "./services";
import AlertBar from "./components/alertBar/AlertBar.vue";

export default {
  name: "App",

  data() {
    return {
      loaded: false,
      drawer: true,
      routes: [
        {
          icon: "mdi-view-dashboard",
          label: "Início",
          path: "/home",
        },
        {
          icon: "mdi-octagram-edit-outline",
          label: "Raspadinhas",
          path: "/scratchs",
        },
        {
          icon: "mdi-account-group-outline",
          label: "Usuários",
          path: "/users",
        },
        {
          icon: "mdi-tray-arrow-down",
          label: "Depósitos",
          path: "/deposits",
        },
        {
          icon: "mdi-tray-arrow-up",
          label: "Saques",
          path: "/withdrawals",
        },
        {
          icon: "mdi-cash-sync",
          label: "Vendas",
          path: "/sales",
        },
      ],
    };
  },

  components: {
    AlertBar,
  },

  created() {
    this.handleSession();
  },

  beforeMount() {
    this.$root.$on("alert", this.handleAlert);
  },

  beforeDestroy() {
    this.$root.$off("alert", this.handleAlert);
  },

  computed: {
    ...mapState(["user"]),

    _authenticated() {
      return this.$store.getters.authenticated;
    },
  },

  methods: {
    ...mapActions(["setToken", "setUser", "setSignOut"]),

    // checks if the token is still valid for a session
    async handleSession() {
      const token = this.$store.getters.token;

      if (token) {
        try {
          const payload = {
            method: "checkAdminToken",
          };

          await request(payload);
        } catch {
          this.signOut();
        }
      }

      this.loaded = true;
    },

    handleAlert(event) {
      this.$refs.alertBar.handleAlert(event);
    },

    async signOut() {
      await this.setSignOut();
      this.$router.push({ path: "/auth" }).catch(() => {
        /* ignore */
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
