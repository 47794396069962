<template>
  <v-container>
    <div
      class="text-h6 text-sm-h5 font-weight-bold text-center text-sm-left mb-6"
    >
      Início
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Home",
};
</script>

<style src="./style.scss" lang="scss" scoped />
