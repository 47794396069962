<template>
  <v-container>
    <div class="d-flex align-md-center flex-column flex-md-row mb-6">
      <div class="text-h6 text-sm-h5 font-weight-bold mb-2 mb-md-0">
        Raspadinhas
      </div>

      <v-spacer />

      <v-btn class="text-none" color="primary" depressed @click="handleNew()">
        Criar raspadinha
      </v-btn>
    </div>

    <loader v-if="loading" />

    <section v-else>
      <v-data-table
        class="rounded-lg elevation-1"
        :headers="headers"
        :items="scratchs"
        hide-default-footer
        disable-pagination
        disable-sort
      >
        <template v-slot:[`item.probability`]="{ item }">
          {{ item.probability }}%
        </template>

        <template v-slot:[`item.price`]="{ item }">
          R$ {{ currencyMask(item.price) }}
        </template>

        <template v-slot:[`item.prizeBalance`]="{ item }">
          R$ {{ currencyMask(item.prizeBalance) }}
        </template>

        <template v-slot:[`item.lockedBalance`]="{ item }">
          R$ {{ currencyMask(item.lockedBalance) }}
        </template>

        <template v-slot:[`item.prizeAmount`]="{ item }">
          R$ {{ currencyMask(item.prizeAmount) }}
        </template>

        <template v-slot:[`item.lockedAmount`]="{ item }">
          {{ item.lockedAmount }}%
        </template>

        <template v-slot:[`item.available`]="{ item }">
          {{ item.available ? "Disponível" : "Indisponível" }}
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-btn small icon @click="handleEdit(item)">
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>

          <v-btn small icon @click="handleDelete(item)">
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </section>

    <ScratchDialog ref="scratchDialog" @success="getScratchs()" />
  </v-container>
</template>

<script>
import { request } from "@/services";
import { currencyMask } from "@/utils";
import ScratchDialog from "./partials/ScratchDialog/ScratchDialog.vue";

export default {
  name: "Scratchs",

  components: {
    ScratchDialog,
  },

  data() {
    return {
      loading: true,
      headers: [
        {
          text: "Nome",
          value: "name",
        },
        {
          text: "Probabilidade",
          value: "probability",
        },
        { text: "Preço (R$)", value: "price" },
        { text: "Valor do premio", value: "prizeAmount" },
        { text: "Saldo a premiar", value: "prizeBalance" },
        { text: "Saldo retido", value: "lockedBalance" },
        { text: "Valor retido", value: "lockedAmount" },
        { text: "Status", value: "available" },
        { value: "actions" },
      ],
      scratchs: [],
    };
  },

  beforeMount() {
    this.getScratchs();
  },

  methods: {
    async getScratchs() {
      try {
        const payload = {
          method: "listRaspadinhas",
        };

        const { data } = await request(payload);

        this.scratchs = data.raspadinhas.map((el) => ({
          id: el.id,
          available: el.disponivel === "1",
          name: el.nome,
          probability: Number(el.probGanhar),
          price: Number(el.valor),
          prizeBalance: Number(el.saldoAPremiar),
          lockedBalance: Number(el.saldoRetido),
          prizeAmount: Number(el.valorPremio),
          lockedAmount: Number(el.valorRetido),
        }));
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    handleNew() {
      this.$refs.scratchDialog.open();
    },

    handleEdit(data) {
      this.$refs.scratchDialog.open(data);
    },

    handleDelete(data) {
      console.log(data);
    },

    currencyMask,
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
